
@import "@/scss/color.scss";

.landing-wrapper {
    flex: 1 0;
    display: flex;

    h1 {
        margin: 20px;
        color: #000;
    }
}

.landing-content {
    :deep(a:not(.button)) {
        color: #000;
    }

    .video-container {
        margin: 20px 0;
        display: flex;
        justify-content: center;
    }

    .copy-container {
        color: #000;
        text-align: center;
        margin: 40px 20px;
    }

    .links-container {
        display: flex;
        justify-content: space-around;
        margin: 40px 20px;
        align-items: center;

        .link-block {
            &.disabled {
                pointer-events: none;
            }
        }

        .link {
            display: flex;

            .link-copy {
                font-size: 1.1em;
                text-decoration: underline;
                display: flex;
                justify-content: space-around;
                align-items: center;
            }

            .link-image {
                max-width: 72px;
                max-height: 72px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                margin: 0 10px;
                overflow: hidden;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: auto;
                    height: auto;
                    max-width: 72px;
                }
            }
        }

        .button {
            margin: 10px 0;
        }
    }

    .footer-container {
        .footer-copy {
            text-align: center;

            a {
                text-decoration: underline;
            }
        }
    }
}

@media (max-width: 1199px) {
    .landing-content {
        flex-basis: 75%;
    }
}

@media (max-width: 767px) {
    .landing-content {
        flex-basis: 100%;
    }
}

@media (max-width: 650px) {
    .links-container {
        flex-direction: column;

        .link {
            margin: 10px;

            .link-image {
                width: 72px;
                height: 72px;
            }
        }
    }

    .resource-links {
        align-items: flex-start !important;
    }
}
