@import '../font';
@import '../helper/_nav';

html, body {
    height: 100%;
}

body {
    // background: url(images/background-blue.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #000000;
    //background-blend-mode: multiply;
    min-height: 100vh;
}

h1 {
    @extend .h1-font;
    color: $text-header;
    text-align: center;
    line-height: 1;
}


html, p {
    @extend .p-font;
}

em {
    font-style: italic;
}

a {
    color: $link-color;
}

button {
    cursor: pointer;
}

.expander {
    flex: 1 0 auto;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-primary {
    color: $primary-color !important;
}

#main-container {
    position: relative;
    flex: 1;
}
#pp-main-container {
    max-height: calc(100vh - 87px);
}

#content {
    height: 100%;
}

#nav-header {
    width: 100%;
    min-height: $nav-header-height-mobile;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-start;
}

#nav-logo {
    margin: 38px 0 21px 65px;
    width: 194px;
    height: 89px;
    background-image: url('~@/scss/images/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center left;
    text-indent: -9999px;
}

#right-header-section {
    display: flex;
}

#language-selector {
    color: white;
    font-weight: 300;
    font-size: 14px;
    margin-right: 57px;
    line-height: 1.1;
    display: flex;
    align-items: center;

    .items {
        display: flex;

        > div::before {
            content: "\00a0\00a0|\00a0\00a0";
        }

        > div:first-child::before {
            content: "";
        }

        > div.selected a {
            text-decoration: underline;
        }
    }
}

#main-menu {
    margin-right: 47px;
    z-index: 5;

    button {
        border: none;
        background-color: transparent;
        padding: 4px;
    }
}

#nav-icon3.open {
    span {
        background: #000;
    }
}

.vjs-big-play-button {
    right: 0;
    left: 0;
    margin: auto;
    top: 0;
    bottom: 0;
}

#menu-items {
    position: fixed;
    //display: none;
    top: 148px;
    background-color: black;
    background-color: rgba(0,0,0,.8);
    font-size: 18px;
    line-height: 36px;
    color: white;

    padding-left: 80px;
    //padding-top: 57px;
    //padding-right: 140px;
    height: calc(100vh - 87px);
    right: -548px;
    transition-property: right;
    transition: .25s ease-in-out;
    overflow-x: auto;

    > div {
        margin-top: 32px;
        width: 450px;
    }

    a {
        text-decoration: none;
    }

    ul {
        list-style: none;
        margin-left: 0;
        padding-left: 0;

        ul {
            li:before {
                content: "-";
                padding-right: 5px;
                color: #959595;
            }
            li.disabled:before {
                color: #6a6a6a;
            }

            li {
                padding-left: 11px;
                //text-indent: -.6em;
            }
            a {
                color: #959595;
            }
        }
    }

    li.disabled a {
        color: #6a6a6a;
    }



    li {
        margin-top: 8px;
        margin-bottom: 3px;
    }

    li.disabled {
        font-weight: 400;

        a {
            cursor: default;
        }
    }

    .sub-list-boundary {
        line-height: .1;
        margin-bottom: 15px;
    }

    .sub-list-boundary:before {
        content: url('~@/scss/images/sub-list-boundary.png');
    }
}

#menu-items.open {
    transition-property: right;
    transition: .25s ease-in-out;
    right: 0;
    display: block;
    z-index:9999;
}

.button {
    background-color: $primary-color;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 18px 29px 16px 29px;
    min-width: 192px;

    text-align: center;
    white-space: nowrap;
    border-radius: 18px;
    display: block;
    text-decoration: none;
    border: none;

    &:hover {
        background-color: darken($primary-color, 10%);
    }

    &.inline {
        display: inline;
    }

    &:disabled {
        background-color: darken($primary-color, 15%);
        color: darken(#fff, 25%);
        cursor: not-allowed;
    }

    @include input-outline;
}

.pp-scrollable {
    overflow: hidden;

    .section-wrapper {
        overflow-x: hidden;
        overflow-y: auto;
    }
}

// IE11 will not flex outside of screen where overflow is hidden. Relocate scroll to content container.
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .pp-scrollable {
        overflow: auto;
        width: 100%;
        height: 100%;

        .section-wrapper {
            overflow: hidden;

            .content-container {
                overflow-y: auto;
            }
        }
    }
}

#testimonial-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    background-color: $secondary-color;
    height: 70px;
    bottom: 0;
    padding-right: 40px;
    width: 100%;

    .heading {
        font-size: 30px;
        font-weight: 300;
        color: $primary-color;
        line-height: .84;
        text-align: center;
        padding-top: 10px;
        margin-right: 40px;
        margin-left: 40px;
    }

    .description {
        font-size: 14px;
        color: $primary-color;
        margin-right: 40px;
        line-height: 1.2;
    }

    .x-button {
        position: absolute;
        cursor: pointer;
        top: -27px;
        left: 133px;
    }

    .x-button:before {
        content: url('~@/scss/images/x-button.png');
    }

    .button {
        margin-right: 40px;
        border-radius: 8px;
        padding: 10px 30px 4px 30px;
    }
}

.header-spacer {
    height: 150px;
    flex-shrink: 0;
}

.testimonial-spacer {
    height: 70px;
    flex-shrink: 0;
}

.errorMessage {
    color: red;
}

.play-button {
    display: block;
    cursor: pointer;
}

.scroll-down {
    font-size: 20px;
    color: $secondary-color;
    letter-spacing: .3em;
    display: flex;
    flex-direction: column;
    align-items: center;

    .text {
        margin-bottom: 6px;
    }
}

.section {
    // background: url(images/background-blue.png);
    background-repeat: no-repeat;
    //background-size: 100% 92%;
    //background-position: 0px 86px;
    //background-attachment: fixed;
    // background-blend-mode: multiply;
    background-size: cover;
    background-position: center 0;
    //background-attachment: fixed;
    //position: relative;
}

// For 2023 rebrand, for pages to have a uniform content container.
.content-container {
    flex: 0 1 50%;
    background-color: $silver;
    padding: 20px 6%;

    @media (max-width: 1199px) {
        flex-basis: 75%
    }

    @media (max-width: 767px) {
        flex-basis: 100%;
    }

    a:not(.button) {
        color: #000;
    }
}

@media (max-width: 1073px) {
    #testimonial-footer .description p {
        font-size: 14px;
    }
}

@media (max-width: 1011px) {
    #testimonial-footer .description p {
        font-size: 14px;
    }
    .header-spacer {
        height: 20px;
    }
}

@media (max-width: 873px) {
    #testimonial-footer .description {
        display: none;
    }
}

@media (max-width: 767px) {
    #main-container, #pp-main-container {
        max-height: calc(100vh - 60px);
    }
    #menu-items {
        top: 86px;
        height: calc(100vh - 60px);
        padding-right: 0;
        width: 100%;
        padding-left: 0;
        right: -100%;

        > div {
            width: 330px;
            margin: 0 auto;
            margin-top: 50px;
        }
    }
    #nav-header {
        height: 60px;

        #nav-logo {
            height: 60px;
            width: 252px;
            margin: 0 0 0 20px;
        }
    }
}

.text-align-center {
    text-align: center
}

.text-align-left {
    text-align: left;
}

.font-size-16 {
    font-size: 16px;
}

.font-family-calibri {
    font-family: Calibri;
}


@media (max-width: 528px) {

    #testimonial-footer {
        height: 60px;
        .heading {
            font-size: 7vw;
            margin-left: -10px;
            margin-right: 20px;
            z-index: 1;
        }

        .button {
            margin-right: 10px;
            padding: 8px 5vw 3px 5vw;
            vertical-align: middle;
        }

        .x-button {
            left: 10vw;
        }
    }

    .testimonial-spacer {
        height: 60px;
    }
}

@media (max-width: 377px) {
    #nav-logo {
        margin-left: 15px;
    }
    #language-selector {
        margin-right: 20px;
    }
    #main-menu {
        margin-right: 15px;
    }

    #menu-items {
        > div {
            transform: scale(.8);
            transform-origin: top;
        }
    }
}
